import { Signal } from '@preact/signals-react'
import { useSignals } from '@preact/signals-react/runtime'
import { FC, useState } from 'react'
import { Icon } from '../lib/types'
import Button from './Form/Button'
import Input from './Form/Input'
import Select from './Form/Select'
import IconRender from './IconRender'
import Modal from './Modal'

type Props = {
  signal: Signal
}

const HeaderText: FC<Props> = ({ signal }) => {
  useSignals()

  const [isActive, toggleActive] = useState(false)

  const save = (property: string, newValue: string) => {
    signal.value = { ...signal.value, [property]: newValue }
  }

  const getLink = (): string => {
    switch (signal.value.type) {
      case Icon.MAIL:
        return `mailto:${signal.value.text}`
      case Icon.PHONE:
        return `tel:${signal.value.text}`
      default:
        return signal.value.link
    }
  }

  return (
    <>
      {isActive && (
        <Modal hide={() => toggleActive(false)}>
          <Input
            title='Text'
            value={signal.value.text}
            save={(newValue) => save('text', newValue)}
            className='mb-5'
          />
          <Select
            title='Type'
            options={{
              [Icon.MAIL]: 'Mail',
              [Icon.PHONE]: 'Phone',
              [Icon.LINKEDIN]: 'Linkedin',
              [Icon.GITHUB]: 'Github',
              [Icon.LOCATION]: 'Location',
            }}
            value={signal.value.type}
            save={(value) => save('type', value)}
            className='mb-5'
          />
          {[Icon.LOCATION, Icon.GITHUB, Icon.LINKEDIN].indexOf(
            signal.value.type,
          ) > -1 && (
            <Input
              title='Link'
              value={signal.value.link}
              save={(newValue) => save('link', newValue)}
            />
          )}
          <Button onClick={() => toggleActive(false)} className='mt-5'>
            Save
          </Button>
        </Modal>
      )}
      <div
        onClick={(e) => {
          e.preventDefault()
          toggleActive(true)
        }}
        className='flex items-center'
      >
        <IconRender icon={signal.value.type} />
        <a href={getLink()} className='mb-0 font-bold text-white'>
          {signal.value.text}
        </a>
      </div>
    </>
  )
}

export default HeaderText
