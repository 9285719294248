import { Competency } from '@/src/lib/types'
import { Signal } from '@preact/signals-react'
import { useSignals } from '@preact/signals-react/runtime'
import { FC, useState } from 'react'
import EntityContainer from '../EntityContainer'
import CompetencyForm from './CompetenciesForm'
import CompetencyComponent from './Competency'

type Props = {
  title: Signal
  entities: Signal
  entityName: string
}

const Comptencies: FC<Props> = ({ title, entities, entityName }) => {
  useSignals()

  const [competencyToEdit, setCompetencyToEdit] = useState<
    Competency | undefined
  >()

  return (
    <div className={`${entities.value.length === 0 ? 'hide-on-save' : ''}`}>
      <EntityContainer
        title={title}
        container={entities}
        currentEntity={competencyToEdit}
        resetCurrentEntity={() => setCompetencyToEdit(undefined)}
      >
        {(onSave, onRemove) => (
          <CompetencyForm
            title={title.value}
            entities={entities}
            save={onSave}
            remove={onRemove}
            competencyToEdit={competencyToEdit}
          />
        )}
      </EntityContainer>
      {entities.value.length === 0 && (
        <p>
          No competencies added. Click the Add button above, to add a comptency.
        </p>
      )}
      {entities.value.length > 0 && (
        <table className='w-full'>
          <tbody>
            {entities.value
              .filter((competency: Competency) => !competency.parentSkill)
              .map((competency: Competency, index: number) => (
                <>
                  <tr
                    key={competency.id}
                    onClick={() => setCompetencyToEdit(competency)}
                    className='cursor-pointer'
                  >
                    <CompetencyComponent
                      competency={competency}
                      topBorder={index === 0}
                    />
                  </tr>
                  {entities.value
                    .filter(
                      (childCompetency: Competency) =>
                        childCompetency.parentSkill === competency.id,
                    )
                    .map((childCompetency: Competency) => (
                      <tr
                        key={competency.id}
                        onClick={() => setCompetencyToEdit(childCompetency)}
                        className='cursor-pointer'
                      >
                        <CompetencyComponent
                          competency={childCompetency}
                          isChild={true}
                        />
                      </tr>
                    ))}
                </>
              ))}
          </tbody>
        </table>
      )}
    </div>
  )
}

export default Comptencies
