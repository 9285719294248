import { Experience } from '@/src/lib/types'
import { useSignals } from '@preact/signals-react/runtime'
import { useState } from 'react'
import { experiences, experiencesTitle } from '../../lib/cv'
import EntityContainer from '../EntityContainer'
import ExperienceComponent from './Experience'
import ExperiencesForm from './ExperiencesForm'

const Experiences = () => {
  useSignals()

  const [experienceToEdit, setExperienceToEdit] = useState<Experience>()

  return (
    <>
      <EntityContainer
        title={experiencesTitle}
        container={experiences}
        currentEntity={experienceToEdit}
        resetCurrentEntity={() => setExperienceToEdit(undefined)}
      >
        {(onSave, onRemove) => (
          <ExperiencesForm
            save={onSave}
            remove={onRemove}
            experienceToEdit={experienceToEdit}
          />
        )}
      </EntityContainer>
      <>
        {experiences.value.length === 0 && (
          <p>
            No experiences added. Click the Add button above, to add an
            experience
          </p>
        )}
        {experiences.value.map((experience: Experience, index: number) => (
          <div
            key={index}
            className={`cursor-pointer block ${index === experiences.value.length - 1 ? '' : 'block--dashed'} experience`}
            onClick={() => {
              setExperienceToEdit(experience)
            }}
          >
            <ExperienceComponent experience={experience} />
          </div>
        ))}
      </>
    </>
  )
}

export default Experiences
