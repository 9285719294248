import { FC, RefObject, useState } from 'react'

type Props = {
  title?: string
  value: string
  save: (value: string) => void
  className?: string
  targetRef?: RefObject<HTMLTextAreaElement>
}
const Textarea: FC<Props> = ({
  title,
  value,
  save,
  targetRef,
  className = '',
}) => {
  const paragraphToNewline = (html: string) => {
    const tempDiv = document.createElement('div')
    tempDiv.innerHTML = html

    let text = ''
    const nodes = tempDiv.childNodes
    nodes.forEach((node) => {
      if (node.nodeName === 'UL') {
        node.childNodes.forEach((li) => {
          if (li.nodeName === 'LI') {
            text += `- ${li.textContent}\n`
          }
        })
      } else if (node.nodeName === 'P') {
        text += `${node.textContent}\n`
      }
    })

    return text.trim()
  }

  const newlineToParagraph = (text: string) => {
    // Regular expression to match list items
    const listItemRegex = /^- (.*)$/gm
    let inList = false

    // Replace list items with <li> elements
    text = text.replace(listItemRegex, (match, p1) => {
      if (!inList) {
        inList = true
        return `<ul class='list-disc ml-5 mt-2'><li>${p1}</li>`
      }
      return `<li>${p1}</li>`
    })

    // Close any open lists
    if (inList) {
      text += '</ul>'
    }

    // Replace any remaining line breaks with paragraph tags
    text = text.replace(/^(?!<ul>|<li>|<\/li>|<\/ul>)(.*)$/gm, (match, p1) => {
      console.log(p1)
      if (p1.startsWith('<')) {
        return p1
      }
      return `<p>${p1}</p>`
    })
    text = text.replace(/<p>\s*<\/p>/gm, '')
    // Return the HTML string
    console.log(text)
    return text
  }

  const [editValue, setEditValue] = useState<string>(paragraphToNewline(value))

  const props: { ref?: RefObject<HTMLTextAreaElement> } = {}
  if (targetRef) {
    props.ref = targetRef
  }

  return (
    <>
      {title && <p className='text-lg mb-1'>{title}</p>}
      <textarea
        rows={5}
        value={editValue}
        onChange={(event) => setEditValue(event.target.value)}
        onBlur={() => save(newlineToParagraph(editValue))}
        className={`outline-none w-full px-4 py-2 border-2 border-gray-300 rounded-md bg-gray-100 text-lg ${className}`}
        {...props}
      />
    </>
  )
}

export default Textarea
