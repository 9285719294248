import { FC, useEffect } from 'react'
import { createPortal } from 'react-dom'

type Props = {
  children: React.ReactNode
  hide: () => void
}

const Modal: FC<Props> = ({ children, hide }) => {
  useEffect(() => {
    document.body.className = `${document.body.className} overflow-hidden`

    return () => {
      document.body.className = document.body.className.replace(
        'overflow-hidden',
        '',
      )
    }
  }, [])

  const close = (confirm: bool) => {
    if (
      confirm &&
      !window.confirm(
        'This will close the dialog and all changes will be lost. Are you sure?',
      )
    ) {
      return false
    }
    hide()
  }

  return createPortal(
    <>
      <div
        className='z-0 fixed top-0 left-0 h-full w-full bg-gray-500 opacity-50 overflow-hidden'
        onClick={() => close(true)}
      />
      <div className='fixed top-2 left-0 right-0 z-10 bg-white w-1/2 mx-auto p-6 overflow-auto max-h-[calc(100vh-2rem)]'>
        <svg
          onClick={() => close(false)}
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 384 512'
          className='absolute top-3 right-3 w-3 cursor-pointer'
        >
          <path d='M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z' />
        </svg>
        {children}
      </div>
    </>,
    document.body,
  )
}

export default Modal
