import { FC, useEffect, useState } from 'react'
import { Position } from '../../lib/types'
import Input from '../Form/Input'
import Textarea from '../Form/Textarea'
import PeriodPicker from '../PeriodPicker'

type Props = {
  position: Position
  save: (position: Position) => void
}
const PositionForm: FC<Props> = ({ position, save }) => {
  const [isCurrentPosition, toggleCurrentPosition] = useState<boolean>(false)
  const [currentPosition, updateCurrentPosition] = useState<Position>(position)

  useEffect(() => {
    updateCurrentPosition(position)
    if (position) {
      toggleCurrentPosition(position.currentPosition)
    }
  }, [position])

  const updateProperty = (property: string, value: string | Date | boolean) => {
    const newPosition = { ...currentPosition, [property]: value }
    updateCurrentPosition(newPosition)
    save(newPosition)
  }

  return (
    <>
      <Input
        title='Title'
        value={position.title}
        save={(title) => updateProperty('title', title)}
        className='mb-5'
      />
      <PeriodPicker
        startDate={position.dateStart}
        endDate={position.dateEnd}
        isCurrent={isCurrentPosition}
        setStartDate={(date) => updateProperty('dateStart', date)}
        setEndDate={(date) => updateProperty('dateEnd', date)}
        setCurrent={(value) => updateProperty('currentPosition', value)}
      />
      <Textarea
        title='Description'
        value={position.description || ''}
        save={(description) => {
          updateProperty('description', description)
        }}
      />
    </>
  )
}

export default PositionForm
