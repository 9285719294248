import Comptencies from './Components/Competencies/Competencies'
import Educations from './Components/Educations/Educations'
import Experiences from './Components/Experiences/Experiences'
import Header from './Components/Header'
import Load from './Components/Load'
import Picture from './Components/Picture'
import Projects from './Components/Projects/Projects'
import Save from './Components/Save'
import Textarea from './Components/Textarea'
import {
  backgroundContent,
  backgroundTitle,
  competencies,
  competenciesTitle,
  introductionContent,
  introductionTitle,
  languages,
  languagesTitle,
  projects,
  projectsTitle,
  skillsContent,
  skillsTitle,
  speaks,
  speaksTitle,
} from './lib/cv'

function App() {
  return (
    <>
      <Load />
      <Save />
      <Header />
      <div className='content'>
        <div className='left'>
          <div className='block'>
            <Textarea
              title={introductionTitle}
              content={introductionContent}
              placeholder='Write a short introduction about yourself and your key achievements. Preferably you will update this section for each position you apply to, to try and target the introduction to the job position.'
            />
          </div>
          <div className='block experiences'>
            <Experiences />
          </div>
          <div className='block education'>
            <Educations />
          </div>
        </div>
        <div className='right max-w-400'>
          <div className='block'>
            <Picture />
          </div>
          <div className='block'>
            <Comptencies
              entityName='Competency'
              title={competenciesTitle}
              entities={competencies}
            />
          </div>
          <div className='block'>
            <Textarea
              title={skillsTitle}
              content={skillsContent}
              placeholder='Add skills seperated by comma, i.e.: Python, FastAPI, React.'
            />
          </div>
          <div className='block'>
            <Comptencies
              entityName='Language'
              title={languagesTitle}
              entities={languages}
            />
          </div>
          <div className='block projects'>
            <Projects
              entityName='project'
              title={projectsTitle}
              projects={projects}
            />
          </div>
          <div className='block achievements'>
            <Projects
              entityName='speak'
              title={speaksTitle}
              projects={speaks}
            />
          </div>
          <div className='block background'>
            <div className='block'>
              <Textarea
                title={backgroundTitle}
                content={backgroundContent}
                placeholder='Write a short bio'
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default App
