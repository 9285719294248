import { FC } from 'react'
import { formatPeriod } from '../lib/dates'
import {
  Education,
  Entity,
  EntityType,
  Experience,
  Position,
} from '../lib/types'

type Props = {
  entity: Entity
}
const Period: FC<Props> = ({ entity }) => {
  const getExperiencePeriod = (experience: Experience): string => {
    let firstPosition: Position
    let lastPosition: Position

    experience!.positions!.forEach((position) => {
      if (!firstPosition) {
        firstPosition = position
        lastPosition = position
      }

      if (position.dateStart < firstPosition.dateStart) {
        firstPosition = position
      }

      if (!lastPosition?.currentPosition) {
        if (
          position.dateEnd &&
          lastPosition?.dateEnd &&
          position.dateEnd > lastPosition.dateEnd
        ) {
          lastPosition = position
        }
      }
    })

    if (!firstPosition! || !lastPosition!) {
      return ''
    }

    const startDate = firstPosition.dateStart
    const endDate = lastPosition.dateEnd!
    const isCurrent = lastPosition.currentPosition || false

    return formatPeriod(startDate, isCurrent, endDate)
  }

  const getEducationPeriod = (education: Education): string => {
    const startDate = education.dateStart
    const endDate = education.dateEnd
    const isCurrent = education.isCurrent || false

    return formatPeriod(startDate, isCurrent, endDate, false)
  }

  const getProjectPeriod = (project: Project): string => {
    const startDate = project.dateStart
    const endDate = project.dateEnd
    const isCurrent = project.isCurrent || false

    return formatPeriod(startDate, isCurrent, endDate, false)
  }

  return (
    <>
      <div className='text-black'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='12'
          height='12'
          fill='currentColor'
          className='bi bi-calendar-fill fill-theme-gray mr-2 inline'
          viewBox='0 0 16 16'
        >
          <path d='M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5h16V4H0V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5z' />
        </svg>
        {entity.type === EntityType.EXPERIENCE && (
          <span>{getExperiencePeriod(entity as Experience)}</span>
        )}
        {entity.type === EntityType.EDUCATION && (
          <span>{getEducationPeriod(entity as Education)}</span>
        )}
        {entity.type === EntityType.PROJECT && (
          <span>{getEducationPeriod(entity as Education)}</span>
        )}
      </div>
    </>
  )
}

export default Period
