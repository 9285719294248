import { Signal } from '@preact/signals-react'
import { useSignals } from '@preact/signals-react/runtime'
import { FC, useEffect, useRef, useState } from 'react'
import Button from './Form/Button'
import Textarea from './Form/Textarea'
import Text from './Text'

type Props = {
  title: Signal
  content: Signal
  placeholder: string
}
const TextareaComponent: FC<Props> = ({ title, content, placeholder }) => {
  useSignals()

  const [isEditorActive, toggleEditor] = useState(false)

  const textareaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus()
    }
  }, [isEditorActive])

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'inherit'
      const computed = window.getComputedStyle(textareaRef.current)

      const height =
        parseInt(computed.getPropertyValue('border-top-width'), 10) +
        parseInt(computed.getPropertyValue('padding-top'), 10) +
        textareaRef.current.scrollHeight +
        parseInt(computed.getPropertyValue('padding-bottom'), 10) +
        parseInt(computed.getPropertyValue('border-bottom-width'), 10)

      textareaRef.current.style.height = `${height}px`
    }
  }, [isEditorActive, content.value])

  return (
    <div className={`${content.value.length === 0 ? 'hide-on-save' : ''}`}>
      <h2>
        <Text signal={title} className='uppercase' />
      </h2>
      {!isEditorActive && (
        <div
          dangerouslySetInnerHTML={{
            __html:
              content.value.length === 0
                ? `<p>${placeholder}</p>`
                : content.value,
          }}
          onClick={() => toggleEditor(true)}
        />
      )}
      {isEditorActive && (
        <div className='relative'>
          <Textarea
            value={content.value}
            save={(value) => (content.value = value)}
            targetRef={textareaRef}
          />
          <Button
            onClick={() => toggleEditor(false)}
            className='absolute bottom-3 right-1'
          >
            Save
          </Button>
        </div>
      )}
    </div>
  )
}

export default TextareaComponent
