export type Position = {
  title: string
  description?: string
  dateStart: Date
  dateEnd?: Date
  currentPosition?: boolean
}

export enum EntityType {
  EXPERIENCE = 'experience',
  EDUCATION = 'education',
  COMPETENCY = 'competecy',
  PROJECT = 'project',
}

export enum SkillLevel {
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
}

export enum Icon {
  MAIL = 'mail',
  PHONE = 'phone',
  LINKEDIN = 'LINKEDIN',
  GITHUB = 'GITHUB',
  LOCATION = 'LOCATION',
}

export interface Entity {
  id: string
  title: string
  type: EntityType
}

export interface Experience extends Entity {
  description: string
  positions: [Position, ...Position[]]
  location: string
}

export interface Education extends Entity {
  location: string
  academy: string
  dateStart: Date
  dateEnd?: Date
  isCurrent?: boolean
}

export interface Competency extends Entity {
  level: SkillLevel
  parentSkill?: string
}

export interface Project extends Entity {
  description: string
  dateStart: Date
  dateEnd?: Date
  isCurrent?: boolean
}

export type HeaderColumn = {
  type: Icon
  link: string
  text: string
}

export type Metadata = {
  name: string
  headerColumn1: HeaderColumn
  headerColumn2: HeaderColumn
  headerColumn3: HeaderColumn
  headerColumn4: HeaderColumn
  introductionTitle: string
  introductionContent: string
  experiencesTitle: string
  experiences: Experience[]
  educationTitle: string
  education: Education[]
  competenciesTitle: string
  competencies: Competency[]
  languagesTitle: string
  languages: Competency[]
  skillsTitle: string
  skillsContent: string
  backgroundTitle: string
  backgroundContent: string
  projectsTitle: string
  projects: Project[]
  speaksTitle: string
  speaks: Project[]
}
