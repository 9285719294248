import { FC } from 'react'
import { formatPeriod } from '../../lib/dates'
import { Experience } from '../../lib/types'
import Location from '../Location'
import Period from '../Period'
type Props = {
  experience: Experience
}
const ExperienceComponent: FC<Props> = ({ experience }) => {
  return (
    <>
      <table>
        <tbody>
          <tr>
            <td>
              <h3>{experience.title}</h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: experience.description,
                }}
              />
            </td>
            <td>
              <Period entity={experience} />
              <Location entity={experience} />
            </td>
          </tr>
        </tbody>
      </table>
      <h4>
        {experience.positions[0].title}
        {experience.positions.length > 1 && (
          <span className='text-black font-medium ml-2'>
            (
            {formatPeriod(
              experience.positions[0].dateStart,
              experience.positions[0].currentPosition || false,
              experience.positions[0].dateEnd,
            )}
            )
          </span>
        )}
      </h4>
      <div
        dangerouslySetInnerHTML={{
          __html: experience.positions[0].description!,
        }}
      />
      {experience.positions.slice(1).map((position, index) => (
        <div key={index} className='mt-5'>
          <h4>
            {position.title}
            <span className='text-black font-medium ml-2'>
              (
              {formatPeriod(
                position.dateStart,
                position.currentPosition || false,
                position.dateEnd,
              )}
              )
            </span>
          </h4>
          <div
            dangerouslySetInnerHTML={{
              __html: position.description!,
            }}
          />
        </div>
      ))}
    </>
  )
}

export default ExperienceComponent
