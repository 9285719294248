import { FC } from 'react'
import { Entity } from '../lib/types'

type Props = {
  entity: Entity
}

const Location: FC<Props> = ({ entity }) => {
  return (
    <div className='text-black'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='12'
        height='12'
        fill='currentColor'
        className='bi bi-geo-alt-fill fill-theme-gray mr-1 inline'
        viewBox='0 0 16 16'
      >
        <path d='M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z' />
      </svg>
      <span>{entity.location}</span>
    </div>
  )
}

export default Location
