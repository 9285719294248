import { FC, useEffect, useState } from 'react'
import Button from './Button'

type Props = {
  value: string
  className?: string
  fullWidth?: boolean
  save: (value: string) => void
}

const InputButton: FC<Props> = ({
  value,
  save,
  fullWidth = false,
  className,
}) => {
  const [editValue, setEditValue] = useState<string>(value)

  useEffect(() => {
    setEditValue(value)
  }, [value])

  return (
    <div className={`relative inline-block ${fullWidth ? 'w-full' : ''}`}>
      <input
        value={editValue}
        onChange={(event) => setEditValue(event.target.value)}
        onBlur={() => save(editValue)}
        className={`outline-none w-full px-4 py-2 border-2 border-gray-300 rounded-md bg-gray-100 ${className}`}
      />
      <Button
        className='absolute top-0 right-0 h-full px-2'
        onClick={() => save(editValue)}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 448 512'
          className='fill-white h-5'
        >
          <path d='M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z' />
        </svg>
      </Button>
    </div>
  )
}

export default InputButton
