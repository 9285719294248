import { FC, useState } from 'react'
import { EntityType, Experience, Position } from '../../lib/types'
import Button from '../Form/Button'
import Input from '../Form/Input'
import Textarea from '../Form/Textarea'
import PositionForm from './PositionForm'

type Props = {
  save: (experience: Experience) => void
  experienceToEdit?: Experience
  remove: (experience: Experience) => void
}

const ExperiencesForm: FC<Props> = ({ save, remove, experienceToEdit }) => {
  const [experience, updateExperience] = useState<Experience>(
    experienceToEdit || {
      id: '',
      type: EntityType.EXPERIENCE,
      title: '',
      description: '',
      location: '',
      positions: [{ title: '', dateStart: new Date() }],
    },
  )

  useState(() => {
    if (experienceToEdit) {
      updateExperience(experienceToEdit)
    }
  }, [experienceToEdit])

  return (
    <>
      <h2>Experience</h2>
      <Input
        title='Company Name'
        value={experience.title}
        save={(title) => updateExperience({ ...experience, title })}
        className='mb-5'
      />
      <Textarea
        title='Company Description'
        value={experience.description || ''}
        save={(description) =>
          updateExperience({
            ...experience,
            description,
          })
        }
        className='mb-5'
      />
      <Input
        title='Location'
        value={experience.location}
        save={(location) => updateExperience({ ...experience, location })}
        className='mb-10'
      />
      <h2 className='mb-2'>Positions</h2>
      <p className='text-lg mb-4'>
        Describe the positions you had within the company.
      </p>
      {experience.positions!.map((position: Position, index: number) => (
        <div key={`${experience.id}-${index}`}>
          <PositionForm
            key={index}
            position={position}
            save={(position: Position) => {
              updateExperience({
                ...experience,
                positions: experience.positions!.toSpliced(index, 1, position),
              })
            }}
          />
          <div className='flex justify-end'>
            {index === experience.positions!.length - 1 && (
              <Button
                theme='blue'
                onClick={() =>
                  updateExperience({
                    ...experience,
                    positions: [
                      ...(experience!.positions as Position[]),
                      { title: '', dateStart: new Date() },
                    ],
                  })
                }
              >
                Add position
              </Button>
            )}
            {experience.positions!.length > 1 && (
              <Button
                onClick={() => {
                  if (confirm('This will remove the position. Are you sure?')) {
                    updateExperience({
                      ...experience,
                      positions: [...experience.positions!.toSpliced(index, 1)],
                    })
                  }
                }}
                theme='red'
                className='ml-3'
              >
                Remove
              </Button>
            )}
          </div>
        </div>
      ))}
      <Button
        onClick={() => {
          // Backwards compatability.
          const newExperience = structuredClone(experience)
          newExperience.type = EntityType.EXPERIENCE
          save(newExperience)
        }}
      >
        Save experience
      </Button>
      {experienceToEdit && (
        <Button
          theme='red'
          className='ml-2'
          onClick={() => {
            if (!confirm('This will delete the experience. Are you sure?')) {
              return
            }
            remove(experience)
          }}
        >
          Delete experience
        </Button>
      )}
    </>
  )
}
export default ExperiencesForm
