import { FC, useEffect, useState } from 'react'

type Props = {
  value: string
  title?: string
  className?: string
  type?: string
  save: (value: string) => void
}

const Input: FC<Props> = ({ value, title, save, className, type = 'text' }) => {
  const [editValue, setEditValue] = useState<string>(value)

  useEffect(() => {
    setEditValue(value)
  }, [value])

  return (
    <>
      {title && <p className='text-lg mb-1'>{title}</p>}
      <input
        type={type}
        value={editValue}
        onChange={(event) => setEditValue(event.target.value)}
        onBlur={() => save(editValue)}
        className={`outline-none ${type === 'date' ? 'w-1/2' : 'w-full'} px-4 py-2 border-2 border-gray-300 rounded-md bg-gray-100 ${className}`}
      />
    </>
  )
}

export default Input
