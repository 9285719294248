import { FC, useState } from 'react'
import { EntityType, Project } from '../../lib/types'
import Button from '../Form/Button'
import Input from '../Form/Input'
import Textarea from '../Form/Textarea'
import PeriodPicker from '../PeriodPicker'

type Props = {
  save: (project: Project) => void
  projectToEdit?: Project
  remove: (project: Project) => void
  title: string
}

const ProjectsForm: FC<Props> = ({ save, remove, projectToEdit, title }) => {
  const [project, updateProject] = useState<Project>(
    projectToEdit || {
      id: '',
      type: EntityType.PROJECT,
      title: '',
      description: '',
      dateStart: new Date(),
    },
  )

  useState(() => {
    if (projectToEdit) {
      updateProject(projectToEdit)
    }
  }, [updateProject])

  return (
    <>
      <h2 className='uppercase'>Add {title}</h2>
      <Input
        title='Name'
        value={project.title}
        save={(title) => updateProject({ ...project, title })}
        className='mb-5'
      />
      <Textarea
        title='Description'
        value={project.description || ''}
        save={(description) =>
          updateProject({
            ...project,
            description,
          })
        }
        className='mb-5'
      />
      <div>
        <PeriodPicker
          startDate={project.dateStart}
          endDate={project.dateEnd}
          isCurrent={project.isCurrent}
          setStartDate={(date) =>
            updateProject({ ...project, dateStart: date })
          }
          setEndDate={(date) => updateProject({ ...project, dateEnd: date })}
          setCurrent={(value) =>
            updateProject({ ...project, isCurrent: value })
          }
        />
      </div>
      <Button
        onClick={() => {
          save(project)
        }}
      >
        Save
      </Button>
      {projectToEdit && (
        <Button
          theme='red'
          className='ml-2'
          onClick={() => {
            if (!confirm('This will delete the project. Are you sure?')) {
              return
            }
            remove(project)
          }}
        >
          Delete
        </Button>
      )}
    </>
  )
}
export default ProjectsForm
