import { Competency } from '@/src/lib/types'
import { FC } from 'react'

type Props = {
  competency: Competency
  topBorder?: boolean
  isChild?: boolean
}
const CompetencyComponent: FC<Props> = ({
  competency,
  topBorder = false,
  isChild = false,
}) => {
  return (
    <>
      <td
        className={`${topBorder ? 'border-y' : 'border-b'} border-theme-light-gray border-dashed py-1`}
      >
        <span className='text-theme-black text-xs font-semibold'>
          {isChild ? <span className='px-2'>-</span> : ''}
          {competency.title}
        </span>
      </td>
      <td
        className={`${topBorder ? 'border-y' : 'border-b'} border-theme-light-gray border-dashed text-right min-w-[100px]`}
      >
        {[...Array(competency.level)].map((level, index) => (
          <svg
            key={`${competency.id}-${index}`}
            xmlns='http://www.w3.org/2000/svg'
            width='13'
            height='13'
            fill='currentColor'
            className='bi bi-star-fill text-theme-gray inline ml-1'
            viewBox='0 0 16 16'
          >
            <path d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z' />
          </svg>
        ))}
      </td>
    </>
  )
}

export default CompetencyComponent
