import { FC } from 'react'

type Props = {
  title?: string
  options: Record<string, string>
  value?: string
  save: (value: string) => void
  className?: string
}
const Select: FC<Props> = ({ title, options, value, save, className }) => {
  return (
    <>
      {title && <p className='text-lg mb-1'>{title}</p>}
      <select
        className={`outline-none w-full px-4 py-2 border-2 border-gray-300 rounded-md bg-gray-100 ${className}`}
        onChange={(event) => save(event.target.value)}
        value={value}
      >
        {Object.keys(options).map((key) => (
          <option key={key} value={key}>
            {options[key]}
          </option>
        ))}
      </select>
    </>
  )
}

export default Select
