import { Education } from '@/src/lib/types'
import { useSignals } from '@preact/signals-react/runtime'
import { useState } from 'react'
import { educations, educationsTitle } from '../../lib/cv'
import EntityContainer from '../EntityContainer'
import Location from '../Location'
import Period from '../Period'
import EducationsForm from './EducationsForm'

const Educations = () => {
  useSignals()

  const [educationToEdit, setEducationToEdit] = useState<
    Education | undefined
  >()

  return (
    <div className={`${educations.value.length === 0 ? 'hide-on-save' : ''}`}>
      <EntityContainer
        title={educationsTitle}
        container={educations}
        currentEntity={educationToEdit}
        resetCurrentEntity={() => setEducationToEdit(undefined)}
      >
        {(onSave, onRemove) => (
          <EducationsForm
            save={onSave}
            remove={onRemove}
            educationToEdit={educationToEdit}
          />
        )}
      </EntityContainer>
      {educations.value.length === 0 && (
        <p>
          No educations added. Click the Add button above, to add an education.
        </p>
      )}
      {educations.value.map((education: Education) => (
        <div
          key={education.id}
          className='block'
          onClick={() => setEducationToEdit(education)}
        >
          <table>
            <tbody>
              <tr>
                <td>
                  <h4>{education.title}</h4>
                  <h5>{education.academy}</h5>
                </td>
                <td>
                  <Period entity={education} />
                  <Location entity={education} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ))}
    </div>
  )
}

export default Educations
