export const htmlToPdf = async (html: string) => {
  const options = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'X-RapidAPI-Key': 'b73be14266msh7dbbb5f069fb8c6p19a3f4jsn13f46aab48e2',
      'X-RapidAPI-Host': 'yakpdf.p.rapidapi.com',
    },
    body: JSON.stringify({
      source: {
        html,
      },
      pdf: {
        format: 'A4',
        scale: 1,
        printBackground: true,
      },
      wait: {
        for: 'navigation',
        waitUntil: 'load',
        timeout: 2500,
      },
    }),
  }

  try {
    return await fetch('https://yakpdf.p.rapidapi.com/pdf', options)
  } catch (error) {
    console.error(error)
  }
}
