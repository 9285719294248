import { FC, useState } from 'react'
import { Education, EntityType } from '../../lib/types'
import Button from '../Form/Button'
import Input from '../Form/Input'
import PeriodPicker from '../PeriodPicker'

type Props = {
  save: (education: Education) => void
  educationToEdit?: Education
  remove: (education: Education) => void
}

const EducationsForm: FC<Props> = ({ save, remove, educationToEdit }) => {
  const [education, updateEducation] = useState<Education>(
    educationToEdit || {
      id: '',
      type: EntityType.EDUCATION,
      title: '',
      academy: '',
      dateStart: new Date(),
      location: '',
    },
  )

  useState(() => {
    if (educationToEdit) {
      updateEducation(educationToEdit)
    }
  }, [updateEducation])

  return (
    <>
      <h2>Education</h2>
      <Input
        title='Name of education'
        value={education.title}
        save={(title) => updateEducation({ ...education, title })}
        className='mb-5'
      />
      <Input
        title='Place of education'
        value={education.academy}
        save={(academy) => updateEducation({ ...education, academy })}
        className='mb-5'
      />

      <Input
        title='Location'
        value={education.location}
        save={(location) => updateEducation({ ...education, location })}
        className='mb-10'
      />
      <div>
        <PeriodPicker
          startDate={education.dateStart}
          endDate={education.dateEnd}
          isCurrent={education.isCurrent}
          setStartDate={(date) =>
            updateEducation({ ...education, dateStart: date })
          }
          setEndDate={(date) =>
            updateEducation({ ...education, dateEnd: date })
          }
          setCurrent={(value) =>
            updateEducation({ ...education, isCurrent: value })
          }
        />
      </div>
      <Button
        onClick={() => {
          save(education)
        }}
      >
        Save Education
      </Button>
      {educationToEdit && (
        <Button
          theme='red'
          className='ml-2'
          onClick={() => {
            if (!confirm('This will delete the education. Are you sure?')) {
              return
            }
            remove(education)
          }}
        >
          Delete Education
        </Button>
      )}
    </>
  )
}
export default EducationsForm
