/* eslint-disable */
const ImageKind = {
  GRAYSCALE_1BPP: 1,
  RGB_24BPP: 2,
  RGBA_32BPP: 3,
}

const PNG_HEADER = new Uint8Array([
  0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a,
])
const CHUNK_WRAPPER_SIZE = 12
const crcTable = new Int32Array(256)

for (let i = 0; i < 256; i++) {
  let c = i

  for (let h = 0; h < 8; h++) {
    if (c & 1) {
      c = 0xedb88320 ^ ((c >> 1) & 0x7fffffff)
    } else {
      c = (c >> 1) & 0x7fffffff
    }
  }

  crcTable[i] = c
}

function crc32(data, start, end) {
  let crc = -1

  for (let i = start; i < end; i++) {
    const a = (crc ^ data[i]) & 0xff
    const b = crcTable[a]
    crc = (crc >>> 8) ^ b
  }

  return crc ^ -1
}

function writePngChunk(type, body, data, offset) {
  let p = offset
  const len = body.length
  data[p] = (len >> 24) & 0xff
  data[p + 1] = (len >> 16) & 0xff
  data[p + 2] = (len >> 8) & 0xff
  data[p + 3] = len & 0xff
  p += 4
  data[p] = type.charCodeAt(0) & 0xff
  data[p + 1] = type.charCodeAt(1) & 0xff
  data[p + 2] = type.charCodeAt(2) & 0xff
  data[p + 3] = type.charCodeAt(3) & 0xff
  p += 4
  data.set(body, p)
  p += body.length
  const crc = crc32(data, offset + 4, p)
  data[p] = (crc >> 24) & 0xff
  data[p + 1] = (crc >> 16) & 0xff
  data[p + 2] = (crc >> 8) & 0xff
  data[p + 3] = crc & 0xff
}

function adler32(data, start, end) {
  let a = 1
  let b = 0

  for (let i = start; i < end; ++i) {
    a = (a + (data[i] & 0xff)) % 65521
    b = (b + a) % 65521
  }

  return (b << 16) | a
}

function deflateSync(literals) {
  return deflateSyncUncompressed(literals)
}

function deflateSyncUncompressed(literals) {
  let len = literals.length
  const maxBlockLength = 0xffff
  const deflateBlocks = Math.ceil(len / maxBlockLength)
  const idat = new Uint8Array(2 + len + deflateBlocks * 5 + 4)
  let pi = 0
  idat[pi++] = 0x78
  idat[pi++] = 0x9c
  let pos = 0

  while (len > maxBlockLength) {
    idat[pi++] = 0x00
    idat[pi++] = 0xff
    idat[pi++] = 0xff
    idat[pi++] = 0x00
    idat[pi++] = 0x00
    idat.set(literals.subarray(pos, pos + maxBlockLength), pi)
    pi += maxBlockLength
    pos += maxBlockLength
    len -= maxBlockLength
  }

  idat[pi++] = 0x01
  idat[pi++] = len & 0xff
  idat[pi++] = (len >> 8) & 0xff
  idat[pi++] = ~len & 0xffff & 0xff
  idat[pi++] = ((~len & 0xffff) >> 8) & 0xff
  idat.set(literals.subarray(pos), pi)
  pi += literals.length - pos
  const adler = adler32(literals, 0, literals.length)
  idat[pi++] = (adler >> 24) & 0xff
  idat[pi++] = (adler >> 16) & 0xff
  idat[pi++] = (adler >> 8) & 0xff
  idat[pi++] = adler & 0xff
  return idat
}

function encode(imgData, kind, forceDataSchema, isMask) {
  const width = imgData.width
  const height = imgData.height
  let bitDepth, colorType, lineSize
  const bytes = imgData.data

  switch (kind) {
    case ImageKind.GRAYSCALE_1BPP:
      colorType = 0
      bitDepth = 1
      lineSize = (width + 7) >> 3
      break

    case ImageKind.RGB_24BPP:
      colorType = 2
      bitDepth = 8
      lineSize = width * 3
      break

    case ImageKind.RGBA_32BPP:
      colorType = 6
      bitDepth = 8
      lineSize = width * 4
      break

    default:
      throw new Error('invalid format')
  }

  const literals = new Uint8Array((1 + lineSize) * height)
  let offsetLiterals = 0
  let offsetBytes = 0

  for (let y = 0; y < height; ++y) {
    literals[offsetLiterals++] = 0
    literals.set(
      bytes.subarray(offsetBytes, offsetBytes + lineSize),
      offsetLiterals,
    )
    offsetBytes += lineSize
    offsetLiterals += lineSize
  }

  if (kind === ImageKind.GRAYSCALE_1BPP && isMask) {
    offsetLiterals = 0

    for (let y = 0; y < height; y++) {
      offsetLiterals++

      for (let i = 0; i < lineSize; i++) {
        literals[offsetLiterals++] ^= 0xff
      }
    }
  }

  const ihdr = new Uint8Array([
    (width >> 24) & 0xff,
    (width >> 16) & 0xff,
    (width >> 8) & 0xff,
    width & 0xff,
    (height >> 24) & 0xff,
    (height >> 16) & 0xff,
    (height >> 8) & 0xff,
    height & 0xff,
    bitDepth,
    colorType,
    0x00,
    0x00,
    0x00,
  ])
  const idat = deflateSync(literals)
  const pngLength =
    PNG_HEADER.length + CHUNK_WRAPPER_SIZE * 3 + ihdr.length + idat.length
  const data = new Uint8Array(pngLength)
  let offset = 0
  data.set(PNG_HEADER, offset)
  offset += PNG_HEADER.length
  writePngChunk('IHDR', ihdr, data, offset)
  offset += CHUNK_WRAPPER_SIZE + ihdr.length
  writePngChunk('IDATA', idat, data, offset)
  offset += CHUNK_WRAPPER_SIZE + idat.length
  writePngChunk('IEND', new Uint8Array(0), data, offset)
  return data
}

export function convertImgDataToPng(imgData, forceDataSchema, isMask) {
  const kind =
    imgData.kind === undefined ? ImageKind.GRAYSCALE_1BPP : imgData.kind
  return encode(imgData, kind, forceDataSchema, isMask)
}
