import { PDFDocument } from 'pdf-lib'
import { parse, stringify } from 'zipson'

export const generatePdfWithMetadata = async (
  bytes: Uint8Array,
  metadata: object,
): Promise<Uint8Array> => {
  const pdfDoc = await PDFDocument.load(bytes)

  // Minify the metadata object with Zipson
  const zippedMetadata = stringify(metadata)
  // TODO: Compression is disabled, since the data to compress is too large, due to saving the picture as Base64
  // Gzip compress the metadata to further minimize size
  // const compressedMetadata = await compressData(zippedMetadata)

  // pdfDoc.setKeywords([compressedMetadata])
  pdfDoc.setKeywords([zippedMetadata])

  return await pdfDoc.save()
}

export const getMetadataFromPdf = async (
  bytes: Uint8Array,
): Promise<object> => {
  const pdfDoc = await PDFDocument.load(bytes)

  const metadataFromPdf = pdfDoc.getKeywords()
  // const decompressedMetadata = await decompressData(metadataFromPdf)

  // const unzippedMetadata = parse(decompressedMetadata)
  const unzippedMetadata = parse(metadataFromPdf)

  return unzippedMetadata
}

function b64decode(str: string) {
  const binaryString = atob(str)
  const len = binaryString.length
  const bytes = new Uint8Array(new ArrayBuffer(len))
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  return bytes
}

const compressData = async (data: string): Promise<string> => {
  const stream = new Blob([data], {
    type: 'application/json',
  }).stream()

  const compressedReadableStream = stream.pipeThrough(
    new CompressionStream('gzip'),
  )

  const compressedResponse = await new Response(compressedReadableStream)

  // Get response Blob
  const blob = await compressedResponse.blob()
  // Get the ArrayBuffer
  const buffer = await blob.arrayBuffer()

  // convert ArrayBuffer to base64 encoded string
  return btoa(String.fromCharCode(...new Uint8Array(buffer)))
}

const decompressData = async (data: string): Promise<string> => {
  const stream = new Blob([b64decode(data)], {
    type: 'application/json',
  }).stream()

  const compressedReadableStream = stream.pipeThrough(
    new DecompressionStream('gzip'),
  )

  const resp = new Response(compressedReadableStream)
  const blob = await resp.blob()
  return await blob.text()
}
