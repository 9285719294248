import { FC } from 'react'
import { formatDate, toDate } from '../lib/dates'
import Input from './Form/Input'

type Props = {
  startDate: Date
  endDate?: Date
  isCurrent?: boolean
  setStartDate: (date: Date) => void
  setEndDate: (date: Date) => void
  setCurrent: (value: boolean) => void
}
const PeriodPicker: FC<Props> = ({
  startDate,
  endDate,
  isCurrent = false,
  setStartDate,
  setEndDate,
  setCurrent,
}) => {
  return (
    <>
      <Input
        type='date'
        title='Start date'
        value={formatDate(startDate)}
        save={(value) => setStartDate(toDate(value))}
        className='mb-5'
      />
      <div className='ml-5 inline-block'>
        <input
          type='checkbox'
          checked={isCurrent}
          onChange={(event) => setCurrent(event.target.checked)}
        />
        <span className='text-lg ml-2'>Current position</span>
      </div>
      {!isCurrent && (
        <>
          <Input
            type='date'
            title='End date'
            value={(endDate && formatDate(endDate)) || ''}
            save={(value) => setEndDate(toDate(value))}
            className='mb-5'
          />
        </>
      )}
    </>
  )
}

export default PeriodPicker
