import { useSignals } from '@preact/signals-react/runtime'
import { useRef } from 'react'
import { picture } from '../lib/cv'
import { uploadPicture } from '../lib/picture'

const Picture = () => {
  useSignals()
  const ref = useRef(null)

  return (
    <div className={`${picture.value.length === 0 ? 'hide-on-save' : ''}`}>
      <input
        type='file'
        className='hidden'
        ref={ref}
        onChange={(event) => uploadPicture(event.target.files[0])}
      />
      <img
        className='w-full cursor-pointer'
        src={picture.value}
        onClick={() => ref.current.click()}
      />
    </div>
  )
}

export default Picture
