import { FC, useState } from 'react'
import { createPortal } from 'react-dom'
import { fromMetadata } from '../lib/cv'
import { getMetadataFromPdf } from '../lib/pdf'
import { extractPictureFromPDF, uploadPicture } from '../lib/picture'
import { Metadata } from '../lib/types'

let processing = false

const Load: FC = () => {
  const [hover, toggleHover] = useState(false)

  let lastTarget: EventTarget | null = null

  const isFile = (event: DragEvent) => {
    for (let i = 0; i < event.dataTransfer!.types.length; i++) {
      if (event.dataTransfer!.types[i] === 'Files') {
        return true
      }
    }
    return false
  }

  const setCv = (file: File) => {
    if (
      !confirm(
        'This will load the CV and overwrite any changes you have made. Are you sure?',
      )
    ) {
      processing = false

      return
    }

    const reader = new FileReader()

    reader.addEventListener(
      'loadend',
      async () => {
        const bytes = new Uint8Array(reader.result! as ArrayBuffer)

        const picture = await extractPictureFromPDF(bytes)
        const metadata = await getMetadataFromPdf(bytes)

        fromMetadata(metadata as Metadata, picture)
        // TODO: This reload should be redundant, if the signals is working properly. To fix this, include useSignals in Experiences.tsx.
        // This will make the application fail when loading an existing CV with experiences, since the date objects from the metadata has not
        // been initialized properly.
        window.location.reload()
      },
      false,
    )

    reader.readAsArrayBuffer(file)
  }

  const dropFile = (file: File) => {
    if (!processing) {
      processing = true

      if (file.name.indexOf('.pdf') > -1) {
        setCv(file)
      } else {
        uploadPicture(file)
      }
    }
  }

  window.addEventListener('dragenter', function (event: DragEvent) {
    if (isFile(event)) {
      lastTarget = event.target
      toggleHover(true)
    }
  })

  window.addEventListener('dragleave', function (event: DragEvent) {
    event.preventDefault()

    if (event.target === lastTarget || event.target === document) {
      toggleHover(false)
    }
  })

  window.addEventListener('dragover', function (event: DragEvent) {
    event.preventDefault()
  })

  window.addEventListener('drop', function (event: DragEvent) {
    event.preventDefault()

    toggleHover(false)

    if (event.dataTransfer!.files.length === 1) {
      dropFile(event.dataTransfer!.files[0])
    }
  })

  return createPortal(
    <>
      {hover && (
        <>
          <div className='absolute top-0 left-0 bg-gray-200 opacity-70 w-full h-full'></div>
          <div className='absolute top-0 left-0 w-full h-full flex items-center justify-center'>
            <p className='text-4xl text-gray-700 inline-block'>
              Drop your existing CV or image here
            </p>
          </div>
        </>
      )}
    </>,
    document.body,
  )
}

export default Load
