import { Signal } from '@preact/signals-react'
import { useSignals } from '@preact/signals-react/runtime'
import { FC, useState } from 'react'
import Input from './Form/InputButton'

type Props = {
  signal: Signal
  className?: string
}

const Text: FC<Props> = ({ signal, className }) => {
  useSignals()

  const [isActive, toggleActive] = useState(false)

  const save = (newValue: string) => {
    signal.value = newValue
    toggleActive(false)
  }

  return (
    <>
      {isActive && (
        <>
          <Input
            value={signal.value}
            className={className}
            save={(newValue) => save(newValue)}
          />
        </>
      )}
      {!isActive && (
        <div className='inline-block' onClick={() => toggleActive(true)}>
          {signal.value}
        </div>
      )}
    </>
  )
}

export default Text
