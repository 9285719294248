import { Signal } from '@preact/signals-react'
import { FC, useState } from 'react'
import { Competency, EntityType, SkillLevel } from '../../lib/types'
import Button from '../Form/Button'
import Input from '../Form/Input'
import Select from '../Form/Select'

type Props = {
  title: string
  entities: Signal
  save: (competency: Competency) => void
  competencyToEdit?: Competency
  remove: (Competency: Competency) => void
}

const CompetenciesForm: FC<Props> = ({
  title,
  entities,
  save,
  remove,
  competencyToEdit,
}) => {
  const [competency, updateCompetency] = useState<Competency>(
    competencyToEdit || {
      id: '',
      type: EntityType.COMPETENCY,
      title: '',
      level: SkillLevel.FIVE,
    },
  )

  useState(() => {
    if (competencyToEdit) {
      updateCompetency(competencyToEdit)
    }
  }, [competencyToEdit])

  return (
    <>
      <h2>Add {title}</h2>
      <Input
        title='Name'
        value={competency.title}
        save={(title) => updateCompetency({ ...competency, title })}
        className='mb-5'
      />
      <Select
        title='Skill Level'
        options={{
          ONE: '1',
          TWO: '2',
          THREE: '3',
          FOUR: '4',
          FIVE: '5',
        }}
        value={SkillLevel[competency.level]}
        save={(level: string) =>
          updateCompetency({ ...competency, level: SkillLevel[level] })
        }
        className='mb-5'
      />
      <Select
        title='Parent Skill'
        options={entities.value.reduce(
          (carry, item) => {
            carry[item.id] = item.title
            return carry
          },
          { none: 'Select' },
        )}
        value={competency.parentSkill}
        save={(parentSkill) => updateCompetency({ ...competency, parentSkill })}
        className='mb-5'
      />
      <Button
        onClick={() => {
          save(competency)
        }}
      >
        Save
      </Button>
      {competencyToEdit && (
        <Button
          className='ml-2'
          theme='red'
          onClick={() => {
            if (!confirm(`This will delete the ${entityName}. Are you sure?`)) {
              return
            }
            remove(competency)
          }}
        >
          Delete
        </Button>
      )}
    </>
  )
}
export default CompetenciesForm
