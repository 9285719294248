export const formatDate = (date: Date): string => {
  return `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? '0' : ''}${date.getMonth() + 1}-${date.getDate() < 10 ? '0' : ''}${date.getDate()}`
}

export const toDate = (date: string) => {
  return new Date(date)
}

export const getMonthName = (date: Date, toShort = false): string => {
  switch (date.getMonth()) {
    case 0:
      return toShort ? 'jan' : 'january'
    case 1:
      return toShort ? 'feb' : 'february'
    case 2:
      return toShort ? 'mar' : 'march'
    case 3:
      return toShort ? 'apr' : 'april'
    case 4:
      return 'may'
    case 5:
      return toShort ? 'jun' : 'june'
    case 6:
      return toShort ? 'jul' : 'july'
    case 7:
      return toShort ? 'aug' : 'august'
    case 8:
      return toShort ? 'sep' : 'september'
    case 9:
      return toShort ? 'oct' : 'october'
    case 10:
      return toShort ? 'nov' : 'november'
    case 11:
      return toShort ? 'dec' : 'december'
  }
}

export const formatPeriod = (
  startDate: Date,
  isCurrent: boolean,
  endDate?: Date,
  showMonth: boolean = true,
) => {
  const formattedStartDate = showMonth
    ? `${getMonthName(startDate, true)}. ${startDate.getFullYear()}`
    : startDate.getFullYear()
  const formattedEndDate =
    isCurrent || !endDate
      ? 'current'
      : showMonth
        ? `${getMonthName(endDate!, true)}. ${endDate!.getFullYear()}`
        : endDate.getFullYear()

  if (formattedStartDate === formattedEndDate) {
    return formattedStartDate
  }

  return `${formattedStartDate} - ${formattedEndDate}`
}
