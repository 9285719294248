import { Entity } from '@/src/lib/types'
import { Signal } from '@preact/signals-react'
import { FC, ReactNode, useEffect, useState } from 'react'
import { generateUUID } from '../lib/utils'
import Button from './Form/Button'
import Modal from './Modal'
import Text from './Text'

type Props = {
  title: Signal
  container: Signal
  currentEntity?: Entity
  resetCurrentEntity: () => void
  children: (
    onSave: (entity: Entity) => void,
    onRemove: (entity: Entity) => void,
  ) => ReactNode
}
const EntityContainer: FC<Props> = ({
  title,
  container,
  currentEntity,
  resetCurrentEntity,
  children,
}) => {
  const [showForm, toggleForm] = useState<boolean>(false)

  useEffect(() => {
    if (currentEntity) {
      toggleForm(true)
    }
  }, [currentEntity])

  const save = (entity: Entity) => {
    if (entity.id) {
      const existingIndex = container.value.findIndex(
        (entityInContainer: Entity) => entityInContainer.id === entity.id,
      )

      container.value = container.value.toSpliced(existingIndex, 1, entity)
    } else {
      const newEntity = structuredClone(entity)
      newEntity.id = generateUUID()

      container.value = [newEntity, ...container.value]
    }

    toggleForm(false)
  }

  const remove = (entity: Entity) => {
    container.value = container.value.filter(
      (entityInContainer: Entity) => entityInContainer.id !== entity.id,
    )

    toggleForm(false)
  }

  return (
    <>
      {showForm && (
        <Modal
          hide={() => {
            resetCurrentEntity()
            toggleForm(false)
          }}
        >
          {children(save, remove)}
        </Modal>
      )}
      <h2 className='flex justify-between items-center'>
        <Text signal={title} className='uppercase' />
        <div className='hide-on-save'>
          <Button
            onClick={() => {
              resetCurrentEntity()
              toggleForm(true)
            }}
            className='mb-1 py-0 font-sm'
            theme='blue'
          >
            Add
          </Button>
        </div>
      </h2>
    </>
  )
}

export default EntityContainer
