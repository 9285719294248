import {
  headerColumn1,
  headerColumn2,
  headerColumn3,
  headerColumn4,
  name,
} from '../lib/cv'
import HeaderText from './HeaderText'
import Text from './Text'

const Header = () => {
  return (
    <>
      <div className='header'>
        <h1>
          <Text signal={name} className='uppercase' />
        </h1>
      </div>
      <div className='bg-black text-white items-center py-3 p-[50px]'>
        <table width='100%' cellPadding={0} cellSpacing='0' border={0}>
          <tbody>
            <tr>
              <td width='25%'>
                <HeaderText signal={headerColumn1} />
              </td>
              <td width='25%'>
                <HeaderText signal={headerColumn2} />
              </td>
              <td width='25%'>
                <HeaderText signal={headerColumn3} />
              </td>
              <td width='25%'>
                <HeaderText signal={headerColumn4} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}

export default Header
