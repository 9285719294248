import { Project } from '@/src/lib/types'
import { Signal } from '@preact/signals-react'
import { useSignals } from '@preact/signals-react/runtime'
import { FC, useState } from 'react'
import EntityContainer from '../EntityContainer'
import Period from '../Period'
import ProjectsForm from './ProjectsForm'

type Props = {
  entityName: string
  title: Signal
  projects: Signal
}
const Projects: FC<Props> = ({ entityName, title, projects }) => {
  useSignals()

  const [projectToEdit, setProjectToEdit] = useState<Project | undefined>()

  return (
    <div className={`${projects.value.length === 0 ? 'hide-on-save' : ''}`}>
      <EntityContainer
        title={title}
        container={projects}
        currentEntity={projectToEdit}
        resetCurrentEntity={() => setProjectToEdit(undefined)}
      >
        {(onSave, onRemove) => (
          <ProjectsForm
            title={title.value}
            save={onSave}
            remove={onRemove}
            projectToEdit={projectToEdit}
          />
        )}
      </EntityContainer>
      {projects.value.length === 0 && (
        <p>
          No {entityName} added. Click the Add button above, to add an{' '}
          {entityName}.
        </p>
      )}
      {projects.value.map((project: Project) => (
        <div
          key={project.id}
          className='block'
          onClick={() => setProjectToEdit(project)}
        >
          <table>
            <tr>
              <td>
                <h3>{project.title}</h3>
              </td>
              <td>
                <Period entity={project} />
              </td>
            </tr>
          </table>
          <p
            dangerouslySetInnerHTML={{
              __html: project.description,
            }}
          />
        </div>
      ))}
    </div>
  )
}

export default Projects
