import { useState } from 'react'

import { toMetadata } from '../lib/cv'
import { generatePdfWithMetadata } from '../lib/pdf'
import { sendFileToBrowser } from '../lib/utils'
import { htmlToPdf } from '../lib/yakpdf'
import Button from './Form/Button'

const Save = () => {
  const [isSaving, toggleSaving] = useState<'RESET' | 'SAVE' | undefined>()

  const save = async () => {
    toggleSaving('SAVE')

    // Remove the save button from the html var
    const html = new DOMParser().parseFromString(
      document.documentElement.outerHTML,
      'text/html',
    )
    const elements = html.getElementsByClassName('hide-on-save')

    Array.from(elements).forEach((item) => item.remove())

    const response = await htmlToPdf(html.documentElement.outerHTML)

    const arrayBuffer = await response!.arrayBuffer()
    const uint8Array = new Uint8Array(arrayBuffer)

    const pdfBytes = await generatePdfWithMetadata(uint8Array, toMetadata())

    sendFileToBrowser(pdfBytes, 'CV.pdf')
    toggleSaving(undefined)
  }

  const reset = async () => {
    if (
      !confirm(
        'This will reset the entire CV and all changes will be lost. Are you sure?',
      )
    ) {
      return false
    }

    toggleSaving('RESET')

    localStorage.clear()
    window.location.reload()
  }

  return (
    <div className='hide-on-save fixed top-2 w-full text-right'>
      <Button
        onClick={reset}
        isLoading={isSaving === 'RESET'}
        theme='red'
        className='mr-5'
      >
        Reset
      </Button>

      <Button onClick={save} isLoading={isSaving === 'SAVE'} className='mr-5'>
        Save as PDF
      </Button>
    </div>
  )
}

export default Save
