import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist'
import { picture } from './cv'
import { convertImgDataToPng } from './png'

GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js'

export const uploadPicture = (file: File) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = function () {
    const image = new Image()

    image.src = reader.result as string
    image.onload = function () {
      if (this.width > 1000) {
        alert('Image is too wide. It must not exceed 1000px.')
      } else {
        picture.value = reader.result
      }
    }
  }
}

export async function extractPictureFromPDF(
  bytes: Uint8Array,
): Promise<string> {
  const loadingTask = getDocument({ data: bytes })

  const pdfDoc = await loadingTask.promise
  const pdfPage = await pdfDoc.getPage(1)

  // Unsure about the exact functionality of this method call, but if this is not done,
  // the objs.get method will throw an non-initialized error.
  await pdfPage.getOperatorList()

  const imgData = pdfPage.objs.get('img_p0_1')
  const imgSrc = convertImgDataToPng(imgData, false, false)

  // We need to image represented in base64. We could just encode the imgSrc to base64, but this results
  // in a +5mb large base64 string, which is too large for localstorage to handle.
  // Therefore, to reduce the size, first render the image onto a img element.
  // Then pass that image to a canvas and get the base64 from that. This will end up in a ~200kb big base64 string.
  const image = document.getElementById(
    'upload-image-image',
  ) as HTMLImageElement

  image.src = URL.createObjectURL(
    new Blob([imgSrc.buffer], { type: 'image/png' } /* (1) */),
  )

  return new Promise<string>((resolve) => {
    image.onload = function () {
      const canvas = document.getElementById(
        'upload-image-canvas',
      ) as HTMLCanvasElement

      canvas.width = imgData.width
      canvas.height = imgData.height
      const ctx = canvas.getContext('2d')!

      ctx.drawImage(image, 0, 0)
      resolve(canvas.toDataURL())
    }
  })
}
